import async from '@/layouts/components/async.component';

// Settings
const EventLog = async(() => import('../event-logs/screens/event-logs.screen'));
const MandatoryDocuments = async(
  () => import('../mandatory-documents/screens/mandatory-documents.screen'),
);

export const enquiryRoutes = {
  path: '/enquiry',
  name: 'Enquiry',
  title: 'ENQUIRY',
  order: 10,
  icon: 'TbTopologyStar3',
  children: [
    {
      path: '/enquiry/event-log',
      name: 'Event Log',
      icon: 'MdOutlineEventNote',
      component: EventLog,
    },
    {
      path: '/enquiry/mandatory-documents',
      name: 'Mandatory Documents',
      icon: 'MdOutlineEventNote',
      component: MandatoryDocuments,
    },
  ],
};
