import { useStores } from '@/stores';
import { useState, useEffect } from 'react';

interface Column {
  dataField: string;
  text: string;
}

export const useColumnManager = (
  initialColumns: Column[],
  fileName?: string,
) => {
  const { loginStore } = useStores();
  const [isColumnFilterVisible, setIsColumnFilterVisible] =
    useState<boolean>(false);
  const [currentColumns, setCurrentColumns] =
    useState<Column[]>(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [columnOrder, setColumnOrder] = useState<Column[]>([]);

  // Unique key structure for selected columns and column order
  const baseKey = `columnSettings_${loginStore.login.companyCode}_${loginStore.login.role}_${loginStore.login.lab}_${loginStore.login.userId}_${fileName}`;
  const selectedColumnsKey = `${baseKey}_selectedColumns`;
  const columnOrderKey = `${baseKey}_columnOrder`;

  const uniqueColumns = Array.from(
    new Set(initialColumns.map((col) => col.dataField)),
  ).map(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (dataField) => initialColumns.find((col) => col.dataField === dataField)!,
  );

  const operationColumn = uniqueColumns.find(
    (col) => col.dataField === 'operation',
  );

  useEffect(() => {
    const storedColumns = localStorage.getItem(selectedColumnsKey);
    const storedOrder = localStorage.getItem(columnOrderKey);

    let columnsToDisplay: Column[] = uniqueColumns;

    if (storedColumns) {
      const savedColumns = JSON.parse(storedColumns);
      setSelectedColumns(savedColumns);

      columnsToDisplay = uniqueColumns.filter((col) =>
        savedColumns.includes(col.dataField),
      );
    } else {
      // If no columns are saved, default to showing all columns
      setSelectedColumns(uniqueColumns.map((col) => col.dataField));
    }

    if (storedOrder) {
      const savedOrder = JSON.parse(storedOrder);

      const orderedColumns = savedOrder
        .map((dataField: string) =>
          uniqueColumns.find((col) => col.dataField === dataField),
        )
        .filter(Boolean) as Column[];

      setColumnOrder(orderedColumns);
      setCurrentColumns(
        orderedColumns.filter((col) => selectedColumns.includes(col.dataField)),
      );
    } else {
      setColumnOrder(uniqueColumns);
      setCurrentColumns(columnsToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColumns, fileName]);

  const handleColumnReorder = (newColumns: Column[]) => {
    const reorderedColumns = Array.from(
      new Set(newColumns.map((col) => col.dataField)),
    ).map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (dataField) => newColumns.find((col) => col.dataField === dataField)!,
    );

    setColumnOrder(reorderedColumns);
    localStorage.setItem(
      columnOrderKey,
      JSON.stringify(reorderedColumns.map((col) => col.dataField)),
    );

    setCurrentColumns(
      reorderedColumns.filter((col) => selectedColumns.includes(col.dataField)),
    );
  };

  const handleColumnToggle = (newSelectedColumns: string[]) => {
    if (operationColumn && !newSelectedColumns.includes('operation')) {
      newSelectedColumns.push('operation');
    }

    setSelectedColumns(newSelectedColumns);
    localStorage.setItem(
      selectedColumnsKey,
      JSON.stringify(newSelectedColumns),
    );

    const columnsToDisplay = columnOrder.length
      ? columnOrder.filter((col) => newSelectedColumns.includes(col.dataField))
      : uniqueColumns.filter((col) =>
          newSelectedColumns.includes(col.dataField),
        );

    setCurrentColumns(columnsToDisplay);
  };

  const filterableColumns = uniqueColumns.filter(
    (column) => column.dataField !== '_id',
  );

  return {
    isColumnFilterVisible,
    setIsColumnFilterVisible,
    currentColumns,
    handleColumnReorder,
    handleColumnToggle,
    filterableColumns,
    selectedColumns,
    columnOrder,
  };
};
