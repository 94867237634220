import React, { useEffect, useState } from 'react';
import { Tooltip, Icons, sortCaret, textFilter } from '@/library/components';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TableBootstrap } from './table-bootstrap.components';
import { useStores } from '@/stores';
import { CgFileDocument, CgNotes } from 'react-icons/cg';

interface PanelApprovalListProps {
  data: any;
  totalSize: number;
  isView?: boolean;
  isDelete?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  isApproval?: boolean;
  selectedId?: string;
  selectedItems?: any;
  filterRecord?: string;
  enteredBy?: string;
  onSelectedRow?: (selectedItem: any, type: string) => void;
  onUpdateFields?: (fields: any, id: string[]) => void;
  onUpdateResult?: (fields: any, id: string, patientResultId: string) => void;
  onExpand?: (items: any) => void;
  onRecheck?: (id: string, patientResultId: string) => void;
  onRetest?: (id: string, patientResultId: string) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
  onClickRow?: (item: any, index: number) => void;
  onReport?: (item: any) => void;
  onFilterRecord?: (item: any) => void;
  onUpdateRecordsByResultList: (list: any) => void;
}

let labId;
let panel;
let pLab;
let patientName;
let department;

export const PanelApprovalList = (props: PanelApprovalListProps) => {
  const { loginStore } = useStores();
  const [selectId, setSelectId] = useState('');
  const [localData, setLocalData] = useState<any>([]);
  const [isAllRecordDisplay, setIsAllRecordDisplay] = useState(false);
  const [fetchIndex, setFetchIndex] = useState<number>(0);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const filterData = (data) => {
    const uniqueList = _.groupBy(
      data,
      (item) => `${item?.labId}-${item?.panelCode}`,
    );
    if (uniqueList)
      setLocalData(
        Object.keys(uniqueList).map((key) => [key, uniqueList[key]]),
      );
  };

  useEffect(() => {
    setSelectId(props.selectedId || '');
    filterData(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.selectedId]);

  const handleExpandClick = (row) => {
    setLocalData([]);
    if (selectId === row._id) {
      setSelectId('');
      props.onExpand && props.onExpand('');
    } else {
      setSelectId(row._id);
      props.onExpand && props.onExpand(row);
    }
    setTimeout(() => {
      filterData(props.data);
    }, 100);
  };

  const handleIconClick = () => {
    setLocalData([]);
    setDropdownVisible(!isDropdownVisible);
    setTimeout(() => {
      filterData(props.data);
    }, 100);
  };

  const handleOptionSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
    row: any,
  ) => {
    const selectedValue = Number.parseInt(event.target.value);

    if (selectedValue === 9) {
      alert('Updating to level 9 is not allowed.');
    } else {
      props.onUpdateFields &&
        props.onUpdateFields(
          {
            validationLevel: selectedValue,
          },
          [row[1][0]._id],
        );
    }
    setDropdownVisible(false);
  };

  return (
    <>
      <div
        className={`${props.isView ? 'shown' : 'hidden'}`}
        style={{ zIndex: 0 }}
      >
        <TableBootstrap
          id='_id'
          data={
            localData?.length > 0
              ? isAllRecordDisplay
                ? localData
                : localData[fetchIndex]
                ? [JSON.parse(JSON.stringify(localData[fetchIndex]))]
                : []
              : []
          }
          totalSize={localData?.length}
          columns={[
            {
              dataField: '_id',
              text: 'Id',
              hidden: true,
              csvExport: false,
            },
            {
              dataField: 'labId',
              text: 'Lab Id',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return <span>{row[1][0]?.labId}</span>;
              },
            },
            {
              dataField: 'name',
              text: 'Patient Name',
              sort: true,
              editable: false,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              sortCaret: (order, column) => sortCaret(order, column),
              filter: textFilter({
                placeholder: 'Patient Name',
                getFilter: (filter) => {
                  patientName = filter;
                },
              }),
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                maxWidth: '250px',
                position: 'relative',
              },
              formatter: (cellContent, row) => (
                <span title={row[1][0].name}>{row[1][0].name}</span>
              ),
            },
            {
              dataField: 'panel',
              text: 'Panel',
              sort: true,
              editable: false,
              headerStyle: {
                fontSize: 0,
              },
              headerClasses: 'textHeader',
              filter: textFilter({
                placeholder: 'Panel',
                getFilter: (filter) => {
                  panel = filter;
                },
              }),
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: 0,
                maxWidth: '250px',
                position: 'relative',
              },
              formatter: (cellContent, row) => (
                <span title={row[1][0]?.panel}>{row[1][0]?.panel}</span>
              ),
            },

            {
              dataField: 'department',
              text: 'Department',
              sort: true,
              editable: false,
            },
            {
              dataField: 'pLab',
              text: 'PLab',
              sort: true,
              editable: false,
              // headerStyle: {
              //   fontSize: 0,
              // },
              // sortCaret: (order, column) => sortCaret(order, column),
              // filter: textFilter({
              //   placeholder: 'Plab',
              //   getFilter: filter => {
              //     pLab = filter;
              //   },
              // }),
              formatter: (cell, row) => {
                return <span>{row[1][0]?.pLab}</span>;
              },
            },
            {
              dataField: 'approvalStatus',
              text: 'Approval Status',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return <span>{row[1][0]?.approvalStatus}</span>;
              },
            },
            {
              dataField: 'comments',
              text: 'Comments',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return <span>{row[1][0]?.comments}</span>;
              },
            },
            {
              dataField: 'dueDate',
              text: 'Due Date',
              sort: true,
              editable: false,
              formatter: (cell, row) => {
                return row[1][0]?.dueDate
                  ? dayjs(row[1][0]?.dueDate).format('DD-MM-YYYY HH:mm:ss')
                  : '';
              },
            },
            {
              dataField: 'operation',
              text: 'Action',
              sort: true,
              editable: false,
              formatter: (cellContent, row) => (
                <div className='flex flex-row gap-1' key={row[1][0]?._id}>
                  {props?.isApproval && row[1][0]?.isResultUpdate ? (
                    <>
                      <Tooltip tooltipText='Approved'>
                        <Icons.IconContext
                          color='#ffffff'
                          size='20'
                          // isDisable={
                          //   row[1][0]?.approvalStatus == 'Hold' ? true : false
                          // }
                          onClick={() => {
                            props.onUpdateFields &&
                              props.onUpdateFields(
                                {
                                  approvalStatus: 'Approved',
                                },
                                _.map(row[1], '_id'),
                              );

                            setFetchIndex(fetchIndex - 1);
                          }}
                        >
                          {Icons.getIconTag(Icons.Iconai.AiFillCheckCircle)}
                        </Icons.IconContext>
                      </Tooltip>
                      <Tooltip
                        tooltipText={`${
                          row[1][0]?.approvalStatus == 'Hold'
                            ? 'Unhold'
                            : 'Hold'
                        } `}
                      >
                        <Icons.IconContext
                          color='#ffffff'
                          size='20'
                          onClick={() => {
                            props.onUpdateFields &&
                              props.onUpdateFields(
                                {
                                  approvalStatus:
                                    row[1][0]?.approvalStatus == 'Hold'
                                      ? 'Pending'
                                      : 'Hold',
                                },
                                _.map(row[1], '_id'),
                              );
                            setFetchIndex(0);
                          }}
                        >
                          {row[1][0]?.approvalStatus == 'Hold'
                            ? Icons.getIconTag(Icons.IconsCi.CiNoWaitingSign)
                            : Icons.getIconTag(Icons.Iconmd.MdBackHand)}
                        </Icons.IconContext>
                      </Tooltip>
                      <Tooltip tooltipText='Recheck'>
                        <Icons.IconContext
                          color='#ffffff'
                          size='20'
                          // isDisable={
                          //   // row[1][0]?.approvalStatus == 'Hold' ? true : false
                          //   true
                          // }
                          onClick={() => {
                            props.onRecheck &&
                              props.onRecheck(
                                row[1][0]?._id,
                                row[1][0]?.patientResultId,
                              );
                            setFetchIndex(0);
                          }}
                        >
                          <Icons.RIcon
                            nameIcon='GoIssueReopened'
                            propsIcon={{
                              color:
                                row[1][0]?.approvalStatus == 'Hold'
                                  ? '#808080'
                                  : '#ffffff',
                            }}
                          />
                        </Icons.IconContext>
                      </Tooltip>
                      <Tooltip tooltipText='Retest'>
                        <Icons.IconContext
                          color='#ffffff'
                          size='20'
                          // isDisable={
                          //   // row[1][0]?.approvalStatus == 'Hold' ? true : false
                          //   true
                          // }
                          onClick={() => {
                            props.onRetest &&
                              props.onRetest(
                                row[1][0]?._id,
                                row[1][0]?.patientResultId,
                              );
                            setFetchIndex(0);
                          }}
                        >
                          <Icons.RIcon
                            nameIcon='RxUpdate'
                            propsIcon={{
                              color:
                                row[1][0]?.approvalStatus == 'Hold'
                                  ? '#808080'
                                  : '#ffffff',
                            }}
                          />
                        </Icons.IconContext>
                      </Tooltip>
                    </>
                  ) : (
                    <span className='text-white'>Still result not update</span>
                  )}
                  {selectId == row[1][0]._id ? (
                    <Tooltip tooltipText='Expand'>
                      <Icons.IconContext
                        color='#ffffff'
                        size='20'
                        onClick={() => {
                          handleExpandClick(row[1][0]);
                        }}
                        key={row[1][0]?._id}
                      >
                        {Icons.getIconTag(Icons.Iconai.AiFillMinusCircle)}
                      </Icons.IconContext>
                    </Tooltip>
                  ) : (
                    <Tooltip tooltipText='Expand'>
                      <Icons.IconContext
                        color='#ffffff'
                        size='20'
                        onClick={() => {
                          handleExpandClick(row[1][0]);
                        }}
                        key={row[1][0]?._id}
                      >
                        {Icons.getIconTag(Icons.Iconai.AiFillPlusCircle)}
                      </Icons.IconContext>
                    </Tooltip>
                  )}

                  <Tooltip
                    tooltipText={
                      loginStore?.login?.validationLevel === 9
                        ? 'Max User Validation'
                        : 'Upgrade Validation Level'
                    }
                  >
                    <div>
                      {loginStore?.login?.validationLevel === 9 ? (
                        <div
                          style={{
                            cursor: 'not-allowed',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icons.IconContext color='#ffffff' size='20'>
                            {Icons.getIconTag(Icons.IconFa.FaUpload)}
                          </Icons.IconContext>
                        </div>
                      ) : isDropdownVisible ? (
                        <select
                          value={row[1][0]?.validationLevel}
                          className='leading-4 p-2 focus:outline-none focus:ring block w-[30px] h-[20px] shadow-sm sm:text-base border border-gray-300 rounded-md'
                          onChange={(e) => handleOptionSelect(e, row)}
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                            ?.filter(
                              (item) =>
                                item > loginStore?.login?.validationLevel,
                            )
                            ?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <div
                          onClick={handleIconClick}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Icons.IconContext color='#ffffff' size='20'>
                            {Icons.getIconTag(Icons.IconFa.FaUpload)}
                          </Icons.IconContext>
                        </div>
                      )}
                    </div>
                  </Tooltip>

                  <Tooltip tooltipText='Report View'>
                    <Icons.IconContext
                      color='#ffffff'
                      size='20'
                      onClick={() => {
                        props.onReport && props.onReport(row);
                      }}
                    >
                      {Icons.getIconTag(Icons.IconBs.BsFilePdf)}
                    </Icons.IconContext>
                  </Tooltip>
                  <Tooltip tooltipText='View Document'>
                    <CgFileDocument
                      color='#ffffff'
                      size='20'
                      onClick={() => {}}
                    />
                  </Tooltip>
                  <Tooltip tooltipText='Special Instructions'>
                    <CgNotes color='#ffffff' size='20' onClick={() => {}} />
                  </Tooltip>
                </div>
              ),
              headerClasses: 'sticky right-0  bg-gray-500 text-white z-50',
              classes: (cell, row, rowIndex, colIndex) => {
                return 'sticky right-0 bg-gray-500';
              },
              style: (cell, row, rowIndex, colIndex) => {
                return {
                  zIndex: 0,
                };
              },
            },
          ]}
          isDelete={props.isDelete}
          isEditModify={props.isUpdate}
          isExport={props.isExport}
          isSelectRow={true}
          fileName='Report Panel Approval'
          onSelectedRow={(rows, type) => {
            props.onSelectedRow && props.onSelectedRow(rows, type);
          }}
          onFilter={(type, filter, page, size) => {
            props.onFilter && props.onFilter(type, filter, page, size);
          }}
          onPageSizeChange={(page, size) => {
            props.onPageSizeChange && props.onPageSizeChange(page, size);
          }}
          clearAllFilter={() => {
            labId('');
            panel('');
          }}
          onFilterRecord={(item) => {
            // if (item == 'Pending') setIsAllRecordDisplay(false);
            // else setIsAllRecordDisplay(true);
            props.onFilterRecord && props.onFilterRecord(item);
          }}
          // diff action to handle
          onUpdateFields={(fields: any, id: string) => {
            props.onUpdateFields && props.onUpdateFields({ ...fields }, [id]);
          }}
          onUpdateResult={(fields, id, patientResultId) => {
            props.onUpdateResult &&
              props.onUpdateResult(fields, id, patientResultId);
          }}
          onPagination={(type) => {
            if (type == 'next') {
              fetchIndex < localData?.length - 1
                ? setFetchIndex(fetchIndex + 1)
                : setFetchIndex(localData?.length - 1);
            } else {
              fetchIndex != 0 && fetchIndex < localData?.length
                ? setFetchIndex(fetchIndex - 1)
                : setFetchIndex(fetchIndex);
            }
          }}
          onFilterByFields={(condition: any) => {
            if (condition?.validationLevel?.toString()) {
              filterData(
                props.data?.filter(
                  (item) => item?.validationLevel == condition?.validationLevel,
                ),
              );
            }
          }}
          onUpdateRecordsByResultList={(list) => {
            props.onUpdateRecordsByResultList(list);
          }}
        />
      </div>
    </>
  );
};
