import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Toast,
  Header,
  PageHeading,
  PageHeadingLabDetails,
  Buttons,
  Grid,
  List,
  Form,
  Svg,
  ModalConfirm,
  ManualImportTabs,
  StaticInputTable,
  ImportFile,
  MainPageHeading,
  Icons,
} from '@/library/components';
import { dayjs, lookupItems, lookupValue } from '@/library/utils';
import { DeliverySchduleList } from '../components';
import { useForm, Controller } from 'react-hook-form';
import { ScheduleFrequency } from '../components';
import { DeliveryScheduleHoc } from '../hoc';
import { useStores } from '@/stores';

import { RouterFlow } from '@/flows';
import { toJS } from 'mobx';
import { resetDeliverySchedule } from '../startup';
import _ from 'lodash';
import * as XLSX from 'xlsx';
import { truncate } from 'fs';

const DeliverySchedule = DeliveryScheduleHoc(
  observer(() => {
    const { loginStore, deliveryScheduleStore, routerStore } = useStores();
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
    } = useForm();

    const [modalConfirm, setModalConfirm] = useState<any>();
    const [hideAddLab, setHideAddLab] = useState<boolean>(true);
    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);
    const [isVersionUpgrade, setIsVersionUpgrade] = useState<boolean>(false);
    const [isExistsRecord, setIsExistsRecord] = useState<boolean>(false);

    useEffect(() => {
      // Default value initialization
      setValue('status', deliveryScheduleStore.deliverySchedule?.status);
      setValue(
        'pStartTime',
        deliveryScheduleStore.deliverySchedule?.pStartTime,
      );
      setValue('pEndTime', deliveryScheduleStore.deliverySchedule?.pEndTime);
      setValue('cutofTime', deliveryScheduleStore.deliverySchedule?.cutofTime);
      setValue(
        'secoundCutofTime',
        deliveryScheduleStore.deliverySchedule?.secoundCutofTime,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryScheduleStore.deliverySchedule]);

    const onSubmitDeliverySchedule = () => {
      if (!isExistsRecord) {
        deliveryScheduleStore.deliveryScheduleService
          .addDeliverySchdule({
            input: isImport
              ? { isImport, arrImportRecords }
              : { isImport, ...deliveryScheduleStore.deliverySchedule },
          })
          .then((res) => {
            if (res.createDeliverySchdule.success)
              Toast.success({
                message: `😊 ${res.createDeliverySchdule.message}`,
              });
            setHideAddLab(true);
            reset();
            resetDeliverySchedule();
            setArrImportRecords([]);
            setIsImport(false);
            setIsVersionUpgrade(false);
          });
      } else {
        Toast.warning({
          message: '😔 Duplicate record found',
        });
      }
    };

    const handleRemoveSchedule = (indexToRemove) => {
      const updatedSchedule =
        deliveryScheduleStore.deliverySchedule.schedule.filter(
          (item, index) => index !== indexToRemove,
        );
      deliveryScheduleStore.updateDeliverySchedule({
        ...deliveryScheduleStore.deliverySchedule,
        schedule: updatedSchedule,
      });
    };

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        const list = data.map((item: any) => {
          return {
            schCode: item['Sch Code'],
            sundayProcessing:
              item['Sunday Processing'] === 'Yes' ? true : false,
            holidayProcessing:
              item['Holiday Processing'] === 'Yes' ? true : false,
            sundayReporting: item['Sunday Reporting'] === 'Yes' ? true : false,
            holidayReporting:
              item['Holiday Reporting'] === 'Yes' ? true : false,
            pStartTime: item['P Start Time'],
            schedule: [],
            pEndTime: item['P End Time'],
            cutofTime: item['Cutof Time'],
            secoundCutofTime: item['Secound Cutof Time'],
            processingType: item['Processing Type'],
            schFrequency: item['Sch Frequency'],
            reportOn: item['Report On'],
            dynamicRT: item['Dynamic RT'],
            dynamicTU: item['Dynamic TU'],
            fixedRT: item['Fixed RT'],
            onTime: item['On Time'] === 'Yes' ? true : false,
            schForDept: item['Sch For Dept'],
            schForPat: item['Sch For Pat'],
            environment: item?.Environment,
            companyCode: item['Company Code'],
            status: 'D',
          };
        });
        setArrImportRecords(list);
      });
      reader.readAsBinaryString(file);
    };

    const checkExistsRecords = async (
      fields: any = deliveryScheduleStore.deliverySchedule,
      isSingleCheck = false,
    ) => {
      const requiredFields = ['schCode', 'status'];
      const isEmpty = requiredFields.find((item) => {
        if (_.isEmpty({ ...fields }[item])) return item;
      });
      if (isEmpty && !isSingleCheck) {
        Toast.error({
          message: `😔 Required ${isEmpty} value missing. Please enter correct value`,
        });
        return true;
      }
      return deliveryScheduleStore.deliveryScheduleService
        .findByFields({
          input: {
            filter: isSingleCheck
              ? { ...fields }
              : {
                  ..._.pick({ ...fields }, requiredFields),
                },
          },
        })
        .then((res) => {
          if (res.findByFieldsDeliverySchdules?.success) {
            setIsExistsRecord(true);
            Toast.error({
              message: '😔 Already some record exists.',
            });
            return true;
          } else {
            setIsExistsRecord(false);
            return false;
          }
        });
    };

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={hideAddLab}
              onClick={() => setHideAddLab(!hideAddLab)}
            />
          )}
        </div>
        <div className='mx-auto flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl ' + (hideAddLab ? 'hidden' : 'shown')
            }
          >
            <ManualImportTabs
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              isImport={isImport}
              onClick={(flag) => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              <Grid cols={2}>
                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Sch Code'
                        disabled={isVersionUpgrade}
                        placeholder={
                          errors.schCode ? 'Please Enter Sch Code' : 'Sch Code'
                        }
                        hasError={!!errors.schCode}
                        value={value}
                        onChange={(schCodeValue) => {
                          const schCode = schCodeValue?.toUpperCase();
                          onChange(schCode);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            schCode,
                          });
                        }}
                        onBlur={(schCode) => {
                          checkExistsRecords(
                            {
                              schCode,
                            },
                            true,
                          );
                        }}
                      />
                    )}
                    name='schCode'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  {deliveryScheduleStore.checkExistsEnvCode && (
                    <span className='text-red-600 font-medium relative'>
                      Code already exits. Please use other code.
                    </span>
                  )}
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Input
                        label='Delivery Schedule'
                        placeholder={
                          errors.schName
                            ? 'Please Enter Delivery Schedule Name'
                            : 'Delivery Schedule Name'
                        }
                        hasError={!!errors.schName}
                        value={value}
                        onChange={(schNameValue) => {
                          const schName = schNameValue?.toUpperCase();
                          onChange(schName);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            schName,
                          });
                        }}
                      />
                    )}
                    name='schName'
                    rules={{ required: true }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Schedule Start From'
                        hasError={!!errors.scheduleStartFrom}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.scheduleStartFrom
                              ? 'border-red  '
                              : 'border-gray-300'
                          } rounded-md`}
                          onChange={(e) => {
                            const scheduleStartFrom = e.target.value as string;
                            onChange(scheduleStartFrom);

                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              scheduleStartFrom,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(
                            routerStore.lookupItems,
                            'SCHEDULE_START_FROM',
                          ).map((item: any, index: number) => (
                            <option key={index} value={item.code}>
                              {lookupValue(item)}
                            </option>
                          ))}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='scheduleStartFrom'
                    rules={{ required: false }}
                    defaultValue=''
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Processing Type'
                        hasError={!!errors.processingType}
                      >
                        <select
                          value={value}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.processingType
                              ? 'border-red  '
                              : 'border-gray-300'
                          } rounded-md`}
                          onChange={(e) => {
                            const processingType = e.target.value as string;
                            onChange(processingType);

                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              processingType,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(
                            routerStore.lookupItems,
                            'PROCESSING_TYPE',
                          ).map((item: any, index: number) => (
                            <option key={index} value={item.code}>
                              {lookupValue(item)}
                            </option>
                          ))}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='processingType'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Form.InputWrapper label='Schedule'>
                    <Grid cols={4}>
                      {/* Schedule Type */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.InputWrapper
                            label='Schedule Type'
                            hasError={!!errors.scheduleType}
                          >
                            <select
                              value={
                                deliveryScheduleStore.localInput?.scheduleType
                              }
                              className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                                errors.scheduleType
                                  ? 'border-red'
                                  : 'border-gray-300'
                              } rounded-md`}
                              onChange={(e) => {
                                const scheduleType = e.target.value;
                                onChange(scheduleType);
                                deliveryScheduleStore.updateLocalInput({
                                  ...deliveryScheduleStore.localInput,
                                  scheduleType,
                                });
                              }}
                            >
                              <option>Select</option>
                              {[
                                'Minutes',
                                'Hours',
                                'Days',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Week 1',
                                'Week 2',
                                'Week 3',
                                'Week 4',
                                'Day of Month',
                                'Day of Month',
                                'Result',
                                'Result',
                                'Batch',
                                'Batch',
                              ].map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </Form.InputWrapper>
                        )}
                        name='scheduleType'
                        rules={{ required: false }}
                        defaultValue=''
                      />

                      {/* Cut-off Time */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Clock
                            label='Cut-off Time'
                            hasError={!!errors.cutOfTime}
                            value={deliveryScheduleStore.localInput?.cutOfTime}
                            onChange={(cutOfTime) => {
                              onChange(cutOfTime);
                              deliveryScheduleStore.updateLocalInput({
                                ...deliveryScheduleStore.localInput,
                                cutOfTime,
                              });
                            }}
                          />
                        )}
                        name='cutOfTime'
                        rules={{ required: false }}
                        defaultValue=''
                      />

                      {/* Holding Factor */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.InputWrapper
                            label='Holding Factor'
                            hasError={!!errors.holdingFactor}
                          >
                            <select
                              value={
                                deliveryScheduleStore.localInput?.holdingFactor
                              }
                              className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                                errors.holdingFactor
                                  ? 'border-red'
                                  : 'border-gray-300'
                              } rounded-md`}
                              onChange={(e) => {
                                const holdingFactor = e.target.value;
                                onChange(holdingFactor);
                                deliveryScheduleStore.updateLocalInput({
                                  ...deliveryScheduleStore.localInput,
                                  holdingFactor,
                                });
                              }}
                            >
                              <option>Select</option>
                              {[
                                'min',
                                'hour',
                                'day',
                                'Mon',
                                'Tue',
                                'Wed',
                                'Thu',
                                'Fri',
                                'Sat',
                                'Sun',
                                'Mon',
                                'Tue',
                                '15',
                                '7',
                                'Positive',
                                'Negative',
                                'hour',
                                'hour',
                              ].map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </Form.InputWrapper>
                        )}
                        name='holdingFactor'
                        rules={{ required: false }}
                        defaultValue=''
                      />

                      {/* Unit */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.InputWrapper
                            label='Unit'
                            hasError={!!errors.unit}
                          >
                            <select
                              value={deliveryScheduleStore.localInput?.unit}
                              className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                                errors.unit ? 'border-red' : 'border-gray-300'
                              } rounded-md`}
                              onChange={(e) => {
                                const unit = e.target.value;
                                onChange(unit);
                                deliveryScheduleStore.updateLocalInput({
                                  ...deliveryScheduleStore.localInput,
                                  unit,
                                });
                              }}
                            >
                              <option>Select</option>
                              {[
                                'Minutes',
                                'Hours',
                                'Days',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Day of Week',
                                'Week 1',
                                'Week 2',
                                'Week 3',
                                'Week 4',
                                'Day of Month',
                                'Day of Month',
                                'Result',
                                'Result',
                                'Batch',
                                'Batch',
                              ].map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </Form.InputWrapper>
                        )}
                        name='unit'
                        rules={{ required: false }}
                        defaultValue=''
                      />

                      {/* Due Factor */}
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Input
                            label='Due Factor'
                            type='number'
                            placeholder={
                              errors.dueFactor
                                ? 'Please Enter Due Factor'
                                : 'Due Factor'
                            }
                            hasError={!!errors.dueFactor}
                            value={deliveryScheduleStore.localInput?.dueFactor}
                            onChange={(dueFactor) => {
                              onChange(dueFactor);
                              deliveryScheduleStore.updateLocalInput({
                                ...deliveryScheduleStore.localInput,
                                dueFactor,
                              });
                            }}
                          />
                        )}
                        name='dueFactor'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Form.Clock
                            label='On Time'
                            hasError={!!errors.onTime}
                            value={deliveryScheduleStore.localInput?.onTime}
                            onChange={(onTime) => {
                              onChange(onTime);
                              deliveryScheduleStore.updateLocalInput({
                                ...deliveryScheduleStore.localInput,
                                onTime,
                              });
                            }}
                          />
                        )}
                        name='onTime'
                        rules={{ required: false }}
                        defaultValue=''
                      />
                      <Buttons.Button
                        className='mt-4'
                        size='medium'
                        type='solid'
                        onClick={() => {
                          const currentSchedule =
                            deliveryScheduleStore.deliverySchedule.schedule ||
                            [];

                          if (
                            deliveryScheduleStore.localInput.onTime ||
                            deliveryScheduleStore.localInput.dueFactor
                          ) {
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              schedule: [
                                ...currentSchedule,
                                deliveryScheduleStore.localInput,
                              ],
                            });

                            deliveryScheduleStore.updateLocalInput({
                              ...deliveryScheduleStore.localInput,
                              cutOfTime: dayjs().format('hh:mm a'),
                              onTime: dayjs().format('hh:mm a'),
                              scheduleType: '',
                              holdingFactor: '',
                              unit: '',
                              dueFactor: 0,
                            });
                          }
                        }}
                      >
                        <Icons.EvaIcon icon='plus-circle-outline' /> Add
                      </Buttons.Button>
                    </Grid>

                    {/* Display Schedule List */}
                    <List space={2} direction='row' justify='center'>
                      {deliveryScheduleStore.deliverySchedule.schedule?.map(
                        (item, index) => (
                          <div key={index} className='mt-2'>
                            <Buttons.Button
                              size='medium'
                              type='solid'
                              icon={Svg.Remove}
                              onClick={() => handleRemoveSchedule(index)}
                            >
                              {`Type: ${item?.scheduleType}, Cut-off: ${item?.cutOfTime}, Holding: ${item?.holdingFactor}, Unit: ${item?.unit}, Due: ${item?.dueFactor}, On Time: ${item?.onTime}`}
                            </Buttons.Button>
                          </div>
                        ),
                      )}
                    </List>
                  </Form.InputWrapper>

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Clock
                        label='Processing Start Time'
                        hasError={!!errors.pStartTime}
                        value={value}
                        onChange={(pStartTime) => {
                          onChange(pStartTime);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            pStartTime,
                          });
                        }}
                      />
                    )}
                    name='pStartTime'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.Clock
                        label='Processing End Time'
                        hasError={!!errors.pEndTime}
                        value={value}
                        onChange={(pEndTime) => {
                          onChange(pEndTime);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            pEndTime,
                          });
                        }}
                      />
                    )}
                    name='pEndTime'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                </List>

                <List direction='col' space={4} justify='stretch' fill>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputWrapper
                        label='Status'
                        hasError={!!errors.status}
                      >
                        <select
                          value={value}
                          disabled={isVersionUpgrade}
                          className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 ${
                            errors.status ? 'border-red  ' : 'border-gray-300'
                          } rounded-md`}
                          onChange={(e) => {
                            const status = e.target.value;
                            onChange(status);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              status,
                            });
                          }}
                        >
                          <option>Select</option>
                          {lookupItems(routerStore.lookupItems, 'STATUS').map(
                            (item: any, index: number) => (
                              <option key={index} value={item.code}>
                                {lookupValue(item)}
                              </option>
                            ),
                          )}
                        </select>
                      </Form.InputWrapper>
                    )}
                    name='status'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputDateTime
                        label='Sample Received Date'
                        placeholder={
                          errors.sampleReceivedDate
                            ? 'Please Enter Sample Received Date'
                            : 'Sample Received Date'
                        }
                        hasError={!!errors.sampleReceivedDate}
                        value={value}
                        minDate={new Date()}
                        onChange={(sampleReceivedDate) => {
                          onChange(sampleReceivedDate);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            sampleReceivedDate,
                          });
                        }}
                      />
                    )}
                    name='sampleReceivedDate'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Form.InputDateTime
                        label='Report Date'
                        placeholder={
                          errors.reportDate
                            ? 'Please Enter Report Date'
                            : 'Report Date'
                        }
                        hasError={!!errors.reportDate}
                        value={value}
                        minDate={new Date()}
                        onChange={(reportDate) => {
                          onChange(reportDate);
                          deliveryScheduleStore.updateDeliverySchedule({
                            ...deliveryScheduleStore.deliverySchedule,
                            reportDate,
                          });
                        }}
                      />
                    )}
                    name='reportDate'
                    rules={{ required: false }}
                    defaultValue=''
                  />
                  <Grid cols={5}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Toggle
                          label='Sunday Processing'
                          hasError={!!errors.sundayProcessing}
                          value={value}
                          onChange={(sundayProcessing) => {
                            onChange(sundayProcessing);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              sundayProcessing,
                            });
                          }}
                        />
                      )}
                      name='sundayProcessing'
                      rules={{ required: false }}
                      defaultValue=''
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Toggle
                          label='Holiday Processing'
                          hasError={!!errors.holidayProcessing}
                          value={value}
                          onChange={(holidayProcessing) => {
                            onChange(holidayProcessing);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              holidayProcessing,
                            });
                          }}
                        />
                      )}
                      name='holidayProcessing'
                      rules={{ required: false }}
                      defaultValue=''
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Toggle
                          hasError={!!errors.sundayReporting}
                          label='Sunday Reporting'
                          value={value}
                          onChange={(sundayReporting) => {
                            onChange(sundayReporting);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              sundayReporting,
                            });
                          }}
                        />
                      )}
                      name='sundayReporting'
                      rules={{ required: false }}
                      defaultValue=''
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Toggle
                          label='Holiday Reporting'
                          hasError={!!errors.holidayReporting}
                          value={value}
                          onChange={(holidayReporting) => {
                            onChange(holidayReporting);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              holidayReporting,
                            });
                          }}
                        />
                      )}
                      name='holidayReporting'
                      rules={{ required: false }}
                      defaultValue=''
                    />

                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.Toggle
                          hasError={!!errors.secondCutoffTimeRequired}
                          label='Second Cut-off Time Required'
                          value={value}
                          onChange={(secondCutoffTimeRequired) => {
                            onChange(secondCutoffTimeRequired);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              secondCutoffTimeRequired,
                            });
                          }}
                        />
                      )}
                      name='secondCutoffTimeRequired'
                      rules={{ required: false }}
                      defaultValue=''
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Form.DeliveryScheduleToggle
                          hasError={!!errors.secondCutoffTimeRequired}
                          label='Schedule For'
                          value={value}
                          onChange={(scheduleForPatAndDept) => {
                            onChange(scheduleForPatAndDept);
                            deliveryScheduleStore.updateDeliverySchedule({
                              ...deliveryScheduleStore.deliverySchedule,
                              scheduleForPatAndDept,
                            });
                          }}
                        />
                      )}
                      name='scheduleForPatAndDept'
                      rules={{ required: false }}
                      defaultValue=''
                    />
                  </Grid>
                </List>
              </Grid>
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={(file) => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}
            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmitDeliverySchedule)}
              >
                Save
              </Buttons.Button>
              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
            </List>
          </div>
          <div className='p-2 rounded-lg shadow-xl overflow-auto'>
            <DeliverySchduleList
              data={deliveryScheduleStore.listDeliverySchedule || []}
              totalSize={deliveryScheduleStore.listDeliveryScheduleCount}
              extraData={{
                lookupItems: routerStore.lookupItems,
              }}
              isView={RouterFlow.checkPermission(
                routerStore.userPermission,
                'View',
              )}
              isDelete={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Delete',
              )}
              isUpdate={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Update',
              )}
              isExport={RouterFlow.checkPermission(
                routerStore.userPermission,
                'Export',
              )}
              onDelete={(selectedItem) => setModalConfirm(selectedItem)}
              onSelectedRow={(rows) => {
                setModalConfirm({
                  show: true,
                  type: 'Delete',
                  id: rows,
                  title: 'Are you sure?',
                  body: 'Do you want to delete selected record?',
                });
              }}
              onUpdateItem={(value: any, dataField: string, id: string) => {
                setModalConfirm({
                  show: true,
                  type: 'Update',
                  data: { value, dataField, id },
                  title: 'Are you sure?',
                  body: 'Do you want to update this record?',
                });
              }}
              onPageSizeChange={(page, limit) => {
                deliveryScheduleStore.fetchDeliverySchedule(page, limit);
                global.filter = { mode: 'pagination', page, limit };
              }}
              onFilter={(type, filter, page, limit) => {
                deliveryScheduleStore.deliveryScheduleService.filter({
                  input: { type, filter, page, limit },
                });
                global.filter = {
                  mode: 'filter',
                  type,
                  page,
                  filter,
                  limit,
                };
              }}
              onApproval={async (records) => {
                const isExists = await checkExistsRecords({
                  ...records,
                  status: 'A',
                });
                if (!isExists) {
                  setModalConfirm({
                    show: true,
                    type: 'Update',
                    data: { value: 'A', dataField: 'status', id: records._id },
                    title: 'Are you sure?',
                    body: 'Do you want to update this record?',
                  });
                }
              }}
            />
          </div>
          <ModalConfirm
            {...modalConfirm}
            click={(action?: string) => {
              switch (action) {
                case 'Delete': {
                  deliveryScheduleStore.deliveryScheduleService
                    .deleteDeliverySchdule({ input: { id: modalConfirm.id } })
                    .then((res: any) => {
                      setModalConfirm({ show: false });
                      if (res.removeDeliverySchdule.success) {
                        Toast.success({
                          message: `😊 ${res.removeDeliverySchdule.message}`,
                        });
                        if (global?.filter?.mode == 'pagination')
                          deliveryScheduleStore.fetchDeliverySchedule(
                            global?.filter?.page,
                            global?.filter?.limit,
                          );
                        else if (global?.filter?.mode == 'filter')
                          deliveryScheduleStore.deliveryScheduleService.filter({
                            input: {
                              type: global?.filter?.type,
                              filter: global?.filter?.filter,
                              page: global?.filter?.page,
                              limit: global?.filter?.limit,
                            },
                          });
                        else deliveryScheduleStore.fetchDeliverySchedule();
                      }
                    });
                  break;
                }

                case 'Update': {
                  deliveryScheduleStore.deliveryScheduleService
                    .updateSingleFiled({
                      input: {
                        _id: modalConfirm.data.id,
                        [modalConfirm.data.dataField]: modalConfirm.data.value,
                      },
                    })
                    .then((res: any) => {
                      if (res.updateDeliverySchdule.success) {
                        setModalConfirm({ show: false });
                        Toast.success({
                          message: `😊 ${res.updateDeliverySchdule.message}`,
                        });
                        if (global?.filter?.mode == 'pagination')
                          deliveryScheduleStore.fetchDeliverySchedule(
                            global?.filter?.page,
                            global?.filter?.limit,
                          );
                        else if (global?.filter?.mode == 'filter')
                          deliveryScheduleStore.deliveryScheduleService.filter({
                            input: {
                              type: global?.filter?.type,
                              filter: global?.filter?.filter,
                              page: global?.filter?.page,
                              limit: global?.filter?.limit,
                            },
                          });
                        else deliveryScheduleStore.fetchDeliverySchedule();
                      }
                    });
                  break;
                }
              }
            }}
            onClose={() => {
              setModalConfirm({ show: false });
            }}
          />
        </div>
      </>
    );
  }),
);

export default DeliverySchedule;
