import { makeObservable, action, observable, computed } from 'mobx';
import { MandatoryDocuments } from '../models';

export class MandatoryDocumentsStore {
  mandatoryDocument!: MandatoryDocuments;
  mandatoryDocumentList: MandatoryDocuments[];
  mandatoryDocumentListCopy: MandatoryDocuments[];
  mandatoryDocumentCount: number;

  constructor() {
    this.mandatoryDocumentList = [];
    this.mandatoryDocumentListCopy = [];
    this.mandatoryDocumentCount = 0;
    this.mandatoryDocument = new MandatoryDocuments({});
    this.mandatoryDocument = {
      ...this.mandatoryDocument,
      eventDate: new Date(),
    };

    makeObservable<MandatoryDocumentsStore, any>(this, {
      mandatoryDocument: observable,
      mandatoryDocumentList: observable,
      mandatoryDocumentListCopy: observable,
      mandatoryDocumentCount: observable,

      mandatoryDocumentService: computed,
      updateMandatoryDocument: action,
    });
  }

  reset() {
    this.mandatoryDocument = new MandatoryDocuments({});
    this.mandatoryDocumentList = [];
    this.mandatoryDocumentCount = 0;
  }

  get mandatoryDocumentService() {
    return;
  }

  fetchMandatoryDocument(page?, limit?) {
    // this.mandatoryDocumentService.listMandatoryDocument(page, limit);
  }

  updateMandatoryDocument = (value: MandatoryDocuments) => {
    this.mandatoryDocument = value;
  };
}
