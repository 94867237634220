import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Form, Buttons, Icons, Svg } from '@/library/components';
import { dayjs, lookupItems, lookupValue } from '@/library/utils';
import { useStores } from '@/stores';

interface ScheduleEntry {
  scheduleType: string;
  cutOfTime: string;
  holdingFactor: string;
  unit: string;
  dueFactor: string;
  onTime: string;
}

interface ModalScheduleModifyProps {
  show?: boolean;
  arrValues?: ScheduleEntry[];
  id?: string;
  onClick: (arrValues: ScheduleEntry[], id: string) => void;
  onClose: () => void;
}

export const ModalScheduleModify = (props: ModalScheduleModifyProps) => {
  const { routerStore } = useStores();
  const [showModal, setShowModal] = useState<boolean | undefined>(props.show);
  const [values, setValues] = useState<ScheduleEntry[]>(props.arrValues || []);

  const [localInput, setLocalInput] = useState<ScheduleEntry>({
    scheduleType: '',
    cutOfTime: dayjs().format('hh:mm a'),
    onTime: dayjs().format('hh:mm a'),
    holdingFactor: '',
    unit: '',
    dueFactor: '',
  });

  useEffect(() => {
    setShowModal(props.show);
    setValues(props.arrValues || []);
  }, [props]);

  const handleAddSchedule = () => {
    const { scheduleType, cutOfTime, holdingFactor, unit, dueFactor, onTime } =
      localInput;
    if (
      !scheduleType ||
      !cutOfTime ||
      !holdingFactor ||
      !unit ||
      !dueFactor ||
      !onTime
    ) {
      alert('Please fill in all schedule fields.');
      return;
    }

    const updatedArrValues = [
      ...values,
      { scheduleType, cutOfTime, holdingFactor, unit, dueFactor, onTime },
    ];

    setValues(updatedArrValues);

    setLocalInput({
      scheduleType: '',
      cutOfTime: '',
      holdingFactor: '',
      unit: '',
      dueFactor: '',
      onTime: '',
    });
  };

  const handleRemoveSchedule = (index: number) => {
    const updatedArrValues = values.filter((_, i) => i !== index);
    setValues(updatedArrValues);
  };

  return (
    <Container>
      {showModal && (
        <>
          <div className='justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex flex-col justify-between p-2 border-b border-solid border-gray-300 rounded-t'>
                  <h4 className='font-semibold text-lg'>
                    Update Schedule Entries
                  </h4>
                </div>
                <div className='relative ml-6 mr-6 p-4 flex-auto'>
                  <div className='grid grid-cols-3 gap-4'>
                    <Form.InputWrapper label='Schedule Type'>
                      <select
                        value={localInput.scheduleType}
                        className='leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 border-gray-300 rounded-md'
                        onChange={(e) =>
                          setLocalInput({
                            ...localInput,
                            scheduleType: e.target.value,
                          })
                        }
                      >
                        <option>Select</option>
                        {lookupItems(
                          routerStore.lookupItems,
                          'SCHEDULE_TYPE',
                        ).map((item, index) => (
                          <option key={index} value={item.code}>
                            {lookupValue(item)}
                          </option>
                        ))}
                      </select>
                    </Form.InputWrapper>

                    <Form.Clock
                      label='Cut-off Time'
                      value={localInput.cutOfTime}
                      onChange={(cutOfTime) =>
                        setLocalInput({ ...localInput, cutOfTime })
                      }
                    />

                    <Form.InputWrapper label='Holding Factor'>
                      <select
                        value={localInput.holdingFactor}
                        className='leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 border-gray-300 rounded-md'
                        onChange={(e) =>
                          setLocalInput({
                            ...localInput,
                            holdingFactor: e.target.value,
                          })
                        }
                      >
                        <option>Select</option>
                        {lookupItems(
                          routerStore.lookupItems,
                          'HOLDING_FACTOR',
                        ).map((item, index) => (
                          <option key={index} value={item.code}>
                            {lookupValue(item)}
                          </option>
                        ))}
                      </select>
                    </Form.InputWrapper>

                    <Form.InputWrapper label='Unit'>
                      <select
                        value={localInput.unit}
                        className='leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 border-gray-300 rounded-md'
                        onChange={(e) =>
                          setLocalInput({ ...localInput, unit: e.target.value })
                        }
                      >
                        <option>Select</option>
                        {lookupItems(routerStore.lookupItems, 'UNIT').map(
                          (item, index) => (
                            <option key={index} value={item.code}>
                              {lookupValue(item)}
                            </option>
                          ),
                        )}
                      </select>
                    </Form.InputWrapper>

                    <Form.Input
                      label='Due Factor'
                      value={localInput.dueFactor}
                      onChange={(dueFactor) =>
                        setLocalInput({ ...localInput, dueFactor })
                      }
                    />

                    <Form.Clock
                      label='On Time'
                      value={localInput.onTime}
                      onChange={(onTime) =>
                        setLocalInput({ ...localInput, onTime })
                      }
                    />
                  </div>

                  <div className='flex justify-end mt-4'>
                    <Buttons.Button
                      size='medium'
                      type='solid'
                      onClick={handleAddSchedule}
                    >
                      <Icons.EvaIcon icon='plus-circle-outline' /> Add
                    </Buttons.Button>
                  </div>

                  <div className='flex flex-wrap gap-2 mt-4'>
                    {values.map((item, index) => (
                      <Buttons.Button
                        key={index}
                        size='medium'
                        type='solid'
                        icon={Svg.Remove}
                        onClick={() => handleRemoveSchedule(index)}
                      >
                        {`Type: ${item.scheduleType}, Cut-off: ${item.cutOfTime}, Holding: ${item.holdingFactor}, Unit: ${item.unit}, Due: ${item.dueFactor}, On Time: ${item.onTime}`}
                      </Buttons.Button>
                    ))}
                  </div>
                </div>
                <div className='flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    onClick={() => {
                      props.onClose();
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    onClick={() => {
                      setShowModal(false);
                      props.onClick(values, props.id || '');
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </Container>
  );
};
