export class DeliverySchedule {
  _id: string;
  schCode: string;
  schName: string;
  sundayProcessing: boolean;
  holidayProcessing: boolean;
  sundayReporting: boolean;
  holidayReporting: boolean;
  pStartTime: string;
  pEndTime: string;
  cutofTime: string;
  secoundCutofTime: string;
  scheduleStartFrom: string;
  secondCutoffTimeRequired: boolean;
  scheduleForPatAndDept: boolean;
  processingType: string;
  schedule: Array<{
    scheduleType: string;
    cutOfTime: string;
    holdingFactor: string;
    unit: string;
    dueFactor: number;
    onTime: string;
  }>;
  schFrequency: any;
  reportOn: string;
  dynamicRT: string;
  dynamicTU: string;
  fixedRT: string;
  onTime: boolean;
  schForDept: string;
  schForPat: string;
  environment: string;
  companyCode: string;
  sampleReceivedDate: Date;
  reportDate: Date;
  status: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    schCode: 'string',
    schName: 'string',
    sundayProcessing: 'boolean',
    holidayProcessing: 'boolean',
    sundayReporting: 'boolean',
    holidayReporting: 'boolean',
    schedule: 'Array',
    pStartTime: 'string',
    pEndTime: 'string',
    scheduleStartFrom: 'string',
    cutofTime: 'string',
    secoundCutofTime: 'string',
    secondCutoffTimeRequired: 'boolean',
    scheduleForPatAndDept: 'boolean',
    processingType: 'string',
    schFrequency: 'any',
    reportOn: 'string',
    dynamicRT: 'string',
    dynamicTU: 'string',
    fixedRT: 'string',
    onTime: 'boolean',
    schForDept: 'string',
    schForPat: 'string',
    environment: 'string',
    companyCode: 'string',
    sampleReceivedDate: 'Date',
    reportDate: 'Date',
    status: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.schCode = rawData.schCode;
    this.schName = rawData.schName;
    this.sundayProcessing = rawData.sundayProcessing;
    this.holidayProcessing = rawData.holidayProcessing;
    this.sundayReporting = rawData.sundayReporting;
    this.holidayReporting = rawData.holidayReporting;
    this.schedule = rawData.schedule;
    this.pStartTime = rawData.pStartTime;
    this.pEndTime = rawData.pEndTime;
    this.cutofTime = rawData.cutofTime;
    this.secoundCutofTime = rawData.secoundCutofTime;
    this.scheduleForPatAndDept = rawData.scheduleForPatAndDept;
    this.secondCutoffTimeRequired = rawData.secondCutoffTimeRequired;
    this.processingType = rawData.processingType;
    this.scheduleStartFrom = rawData.scheduleStartFrom;
    this.schFrequency = rawData.schFrequency;
    this.reportOn = rawData.reportOn;
    this.dynamicRT = rawData.dynamicRT;
    this.dynamicTU = rawData.dynamicTU;
    this.fixedRT = rawData.fixedRT;
    this.onTime = rawData.onTime;
    this.schForDept = rawData.schForDept;
    this.schForPat = rawData.schForPat;
    this.environment = rawData.environment;
    this.companyCode = rawData.companyCode;
    this.sampleReceivedDate = rawData.sampleReceivedDate;
    this.reportDate = rawData.reportDate;
    this.status = rawData.status;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class LocalInput {
  scheduleType: string;
  cutOfTime: string;
  holdingFactor: string;
  unit: string;
  dueFactor: number;
  onTime: string;
  constructor(rawData: { [key in string]: any }) {
    this.scheduleType = rawData.scheduleType;
    this.cutOfTime = rawData.cutOffTime;
    this.holdingFactor = rawData.holdingFactor;
    this.unit = rawData.unit;
    this.dueFactor = rawData.dueFactor;
    this.onTime = rawData.onTime;
  }
}
