export class MandatoryDocuments {
  _id: string;
  id: number;
  pId: number;
  labId: number | string;
  testCode: string;
  documentFor: string;
  documentType: string;
  documentNumber: number;
  attachment: any;
  attachedBy: string;
  eventDate: Date;
  comments: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    id: 'number',
    pId: 'number',
    labId: 'number | string',
    testCode: 'string',
    documentFor: 'string',
    documentType: 'string',
    documentNumber: 'number',
    attachment: 'any',
    attachedBy: 'string',
    eventDate: 'Date',
    comments: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in keyof MandatoryDocuments]?: any }) {
    this._id = rawData._id;
    this.id = rawData.id;
    this.pId = rawData.pId;
    this.labId = rawData.labId;
    this.testCode = rawData.testCode;
    this.documentFor = rawData.documentFor;
    this.documentType = rawData.documentType;
    this.documentNumber = rawData.documentNumber;
    this.attachment = rawData.attachment;
    this.attachedBy = rawData.attachedBy;
    this.eventDate = rawData.eventDate;
    this.comments = rawData.comments;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
